@import "~@fontsource/roboto/latin-ext.css"

*
  -webkit-font-smoothing: antialiased
  text-rendering: optimizeLegibility
  font-smoothing: antialiased
  font-family: "Roboto", 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
  outline: none
  font-size: unset
  line-height: unset
  font-variant: unset

  &:focus
    outline: none !important

  --holo-image-background-image: linear-gradient(-45deg, #bd34fe 50%, #47caff 50%)
  --holo-image-filter: blur(72px)
  --holo-background: -webkit-linear-gradient(120deg, #bd34fe 30%, #41d1ff)
  --text-color-1: rgba(255, 255, 245, .86)
  --text-color-2: rgba(235, 235, 245, .6)
  --text-color-3: rgba(235, 235, 245, .38)

body
  margin: 0
  background-color: rgb(231, 235, 240)
  background-image: url(data:image/pngbase64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAMklEQVRYhe3PoQ0AIBRDwQbNEszAOuw/B/kGg0WRu+SZugYAgA/1sWb14lm7FgAAOJJsjOUBe5fO1bAAAAAASUVORK5CYII=)

main
  > div
    display: flex
    flex-direction: column
    min-height: 100vh

    .main-content
      display: flex
      flex: 1
      justify-content: center
      align-items: center

.title
  font-size: 1.5rem
  font-weight: 700
  font-style: normal

.jumbotronx
  padding: 2rem 1rem
  margin-bottom: 2rem
  background-color: #e9ecef
  border-radius: .3rem

.ant-message
  z-index: 9999

.quoted
  background: #e8ebeb
  border-radius: 5px
  padding: 2px 5px 2px 5px
  font-size: 16px
  margin: 2px
  font-weight: 700
  text-align: center

@keyframes border-transform
  0%, 100%
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%
  14%
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%
  28%
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%
  42%
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%
  56%
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%
  70%
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%
  84%
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%

.border-dance
  animation: border-transform 10s linear infinite alternate forwards

.hidden
  display: none !important

.holow
  width: 200px
  height: 200px
  border-radius: 50%
  background-image: var(--holo-image-background-image)
  filter: var(--holo-image-filter)